import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const ProviderStepper = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(true);
  const [values, setValues] = useState({
    country: null,
    city: null,
    district: null,
    birthday: null,
  });
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const handleComplete = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.put(
        `${API_URL}users/register_complate`,
        values,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setModalVisible(false);
        localStorage.setItem("pet_user", JSON.stringify(response.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your information has been successfully saved"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
      setModalVisible(false);
    }
  };

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getCities = async () => {
      const response = await axios.get(`${API_URL}locations/cities`);

      setCities(response.data);
    };
    getCities();
  }, []);

  const handleCityChange = async (e) => {
    const selectedCity = e.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      city: selectedCity,
      district: null,
    }));

    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const response = await axios.get(
      `${API_URL}locations/districts/${selectedCity}`
    );

    setDistricts(response.data);
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "60%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  return (
    <div className="container">
      <Modal
        open={modalVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="col-10 col-lg-6" sx={boxStyle}>
          <form className="contact-form" onSubmit={handleComplete}>
            <div className="d-flex form-grp flex-column justify-content-center align-items-center">
              <div className="col-md-10 col">
                <label className="form-label" htmlFor="birthday">
                  {t("birthday")}
                </label>
                <DatePicker
                  dateFormat={"dd/MM/yyyy"}
                  id="birthday"
                  wrapperClassName="w-100"
                  selected={values.birthday}
                  onChange={(date) => {
                    setValues({ ...values, birthday: date });
                  }}
                  className="form-control"
                />
              </div>
              <div className="d-flex mt-2 col-md-10 col flex-column">
                <label className="form-label" htmlFor="city">
                  {t("city")}
                </label>
                <select
                  required
                  style={{
                    padding: "8px",
                    backgroundColor: "#637579",
                    borderRadius: "5px",
                    marginBlock: "5px",
                  }}
                  id="city"
                  className="form-select btn"
                  onChange={handleCityChange}
                  aria-label="Default select example"
                  defaultValue=""
                >
                  <option
                    style={{ backgroundColor: "#0a313b" }}
                    value=""
                    disabled
                  >
                    {t("select")}
                  </option>

                  {cities &&
                    cities.map((city) => (
                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        key={city.sehir_adi}
                        value={city.id}
                      >
                        {city.sehir_adi}
                      </option>
                    ))}
                </select>
              </div>
              <div className="d-flex col-md-10 col flex-column">
                <label className="form-label" htmlFor="district">
                  {t("district")}
                </label>
                <select
                  required
                  style={{
                    padding: "8px",
                    backgroundColor: "#637579",
                    borderRadius: "5px",
                    marginBlock: "5px",
                  }}
                  id="district"
                  className="form-select btn"
                  disabled={!values.city}
                  defaultValue=""
                  onChange={(e) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      district: e.target.value,
                    }));
                  }}
                  aria-label="Default select example"
                >
                  <option
                    style={{ backgroundColor: "#0a313b" }}
                    value=""
                    disabled
                  >
                    {t("district")}
                  </option>

                  {districts &&
                    districts.map((district) => (
                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        key={district.ilce_adi}
                        value={district.id}
                      >
                        {district.ilce_adi}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                style={{ width: "80%", justifyContent: "center" }}
                className="btn"
              >
                {t("save")}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ProviderStepper;
