import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Swal from "sweetalert2";

function AdoptDetailComponent({ values }) {
  const { t } = useTranslation();
  const [isOffered, setIsOffered] = useState(false);
  const [message, setMessage] = useState("");
  const [isCheckbox, setIsCheckbox] = useState(false);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    checkOffer();
  }, []);

  const sendAdoptRequest = async () => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;
    try {
      const response = await axios.post(
        `${API_URL}adopt/offer/${values.adopt._id}`,
        { message, isCheckbox },
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your request has been sent successfully"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.error("Error sending adopt request:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: t(error.response.data.message),
      });
    }
  };

  const checkOffer = async () => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(
        `${API_URL}adopt/${values.adopt._id}/check-offer`,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setIsOffered(response.data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title">{values.adopt.pet.fullname}</h4>
              <div className="breeder-details-img">
                <img
                  src={values.adopt.pet.pet_image || "/img/lost_default.png"}
                  className="w-100"
                  alt=""
                />
              </div>
              <div className="breeder-info-item">
                <h6>{t("introduction")}:</h6>
                <p>{values.adopt.desc}</p>
              </div>
              <div className="breeder-dog-info">
                <h5 className="title">{t("pet_Information")}</h5>
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("gender")}:</h6>
                      <span>{values.adopt.pet.gender}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("age")}:</h6>
                      <span>
                        {calculateAge(values.adopt.pet.birthday)} {t("years")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("color")}:</h6>
                      <span>{values.adopt.pet.color}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("size")}:</h6>
                      <span>
                        {values.adopt.pet.size} {t("lbs")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("city")}:</h6>
                      <span>
                        {" "}
                        {values.adopt.city} {`- ${values.adopt.district}`}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("breed")}:</h6>
                      <span>{values.adopt.pet.breed}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="breeder-dog-info">
                <h5 className="title">{t("other_Information")}</h5>
                <div className="column">
                  <div>
                    <h5>{t("medicines")}</h5>
                    <p> {values.adopt.pet.medicine}</p>
                  </div>
                  <div>
                    <h5>{t("allergy")}</h5>
                    <p> {values.adopt.pet.allergy}</p>
                  </div>
                  <div>
                    <h5>{t("special_cases")}</h5>
                    <p> {values.adopt.pet.special_case}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="breeder-sidebar">
              {isOffered ? (
                <div className="widget breeder-widget">
                  <div className="breeder-widget-title mb-20">
                    <h5 className="title">{t("adoption_request_form")}</h5>
                  </div>
                  <form
                    className="sidebar-find-pets"
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendAdoptRequest();
                    }}
                  >
                    {/* <div className="form-grp">
                    <i className="fas fa-money-bill"></i>
                    <input
                      required
                      type="number"
                      step={0.1}
                      id="price"
                      placeholder={t("price")}
                    />
                  </div> */}
                    <div className="form-grp">
                      <i className="fas fa-comment"></i>
                      <textarea
                        name=""
                        id="comment"
                        placeholder={t("message")}
                        style={{ resize: "none", color: "gray" }}
                        cols="5"
                        rows="4"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      className="form-grp"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                      }}
                    >
                      <Checkbox
                        checked={isCheckbox}
                        onChange={(e) => setIsCheckbox(e.target.checked)}
                      />
                      <Typography style={{ fontSize: 14, marginLeft: "8px" }}>
                        {t("adopt_warning2")}
                      </Typography>
                    </div>
                    <div lassName="form-grp">
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: "8px",
                          paddingBottom: "10px",
                        }}
                      >
                        {t("adopt_warning")}
                      </Typography>
                    </div>
                    <div lassName="form-grp">
                      <Typography
                        style={{
                          fontSize: 14,
                          marginLeft: "8px",
                          paddingBottom: "10px",
                        }}
                      >
                        {t("adopt_warning3")}
                      </Typography>
                    </div>
                    <button type="submit" className="btn">
                      {t("send")}
                    </button>
                  </form>
                </div>
              ) : (
                <div className="widget breeder-widget">
                  <div className="breeder-widget-title d-flex justify-content-center flex-column align-items-center">
                    <img
                      src="/img/offer/sent.png"
                      alt="approived"
                      width={100}
                    />
                    <Typography className="mt-3">
                      {t("adopt_offer_sent")}
                    </Typography>
                  </div>
                </div>
              )}
              {/* <div className="widget sidebar-newsletter my-3">
                <div className="sn-icon">
                  <img src="/img/icon/sn_icon.png" alt="" />
                </div>
                <div className="sn-title">
                  <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                  <p>{t("Sign_up_For_Latest_News")}</p>
                </div>
                <form className="sn-form">
                  <input type="text" placeholder={t("Enter_Your_Email")} />
                  <button className="btn">{t("newsletter_button")}</button>
                </form>
              </div> */}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptDetailComponent;
