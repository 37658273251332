import React, { useState } from "react";
import {
  Modal,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "70%",
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "1px solid #fff",
  boxShadow: 24,
  p: 2,
  overflowY: "auto",
  maxHeight: "90%",
};

const CategoryItem = ({
  category,
  name,
  depth = 0,
  selectedCategory,
  setSelectedCategory,
  onCategorySelect,
}) => {
  const [open, setOpen] = useState(false);
  const hasChildren =
    (typeof category === "object" && category !== null) ||
    Array.isArray(category);

  const handleClick = () => {
    setOpen(!open);
    setSelectedCategory(name);
    onCategorySelect(name);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        style={{
          paddingLeft: depth * 20,
          backgroundColor: selectedCategory.includes(name)
            ? "#e0e0e0"
            : "inherit",
        }}
      >
        {hasChildren && (
          <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        )}
        <ListItemText primary={name} />
      </ListItem>
      {Array.isArray(category) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.map((item, index) => (
              <ListItem
                button
                key={index}
                style={{
                  paddingLeft: (depth + 1) * 20,
                  backgroundColor: selectedCategory.includes(item)
                    ? "#e0e0e0"
                    : "inherit",
                }}
                onClick={() => {
                  setSelectedCategory(item);
                  onCategorySelect(item);
                }}
              >
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.entries(category).map(([key, value]) => (
              <CategoryItem
                key={key}
                category={value}
                name={key}
                depth={depth + 1}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                onCategorySelect={onCategorySelect}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

function SelectedImage({ fileUrls }) {
  return (
    <div>
      <Typography>{fileUrls.length} tane görsel seçildi</Typography>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {fileUrls.map((fileUrl, index) => (
          <img
            key={index}
            src={fileUrl}
            alt=""
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        ))}
      </div>
    </div>
  );
}

function AddProductModal({ open, onClose, token, categories }) {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [base64Images, setBase64Images] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    const newFileUrls = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setFileUrls(newFileUrls);
    if (selectedFiles.length > 4) {
      onClose();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You can only upload up to 4 images.",
      });
      setFileUrls([]);
      return;
    }

    const newBase64Images = [];
    Array.from(selectedFiles).forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        newBase64Images.push(base64String);

        if (newBase64Images.length === selectedFiles.length) {
          setBase64Images([...base64Images, ...newBase64Images]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        price: e.currentTarget.price.value,
        name: e.currentTarget.product.value,
        introduction: e.currentTarget.introduction.value,
        category: selectedCategory,
        images: base64Images,
      };

      const response = await axios.post(`${API_URL}products`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        onClose();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your product has been added"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      onClose();
      console.log("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleCategorySelect = (category) => {
    let updatedCategories;

    if (selectedCategory.includes(category)) {
      updatedCategories = selectedCategory.filter((cat) => cat !== category);
    } else {
      updatedCategories = [...selectedCategory, category];
    }

    setSelectedCategory(updatedCategories);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="col-10 col-lg-6" sx={boxStyle}>
        <div
          onClick={onClose}
          style={{
            position: "absolute",
            cursor: "pointer",
            top: 5,
            right: 5,
            zIndex: 9999,
          }}
        >
          <img src="/img/close.png" alt="close_button" width={40} height={40} />
        </div>
        <form onSubmit={handleAddProduct} className="contact-form">
          <div className="form-grp d-flex justify-content-center flex-column align-items-center">
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              {t("Products_Image")}
            </Typography>
            <label htmlFor="fileInput">
              <div
                className="d-flex justify-content-center align-items-center mb-4"
                style={{
                  backgroundColor: "#637579",
                  width: "200px",
                  height: "200px",
                  cursor: "pointer",
                }}
              >
                <i style={{ color: "white" }} className="fas fa-plus fa-3x"></i>
              </div>
            </label>
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => handleFileSelect(e)}
              multiple
            />
            <SelectedImage fileUrls={fileUrls} />
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("Products_Name")}
              </Typography>
              <input required id="product" type="text" />
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("introduction")}
              </Typography>
              <textarea
                type="text"
                style={{ resize: "none" }}
                id="introduction"
                className="form-control"
              />
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "10px",
                }}
              >
                {t("category")}
              </Typography>
              {categories &&
                categories.map((category, index) => (
                  <CategoryItem
                    key={index}
                    category={Object.values(category)[0]}
                    name={Object.keys(category)[0]}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    onCategorySelect={handleCategorySelect}
                  />
                ))}
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("price")}
              </Typography>
              <input required id="price" type="text" />
            </div>
            <div style={{ width: "80%" }} className="mt-3">
              <button
                className="btn btn-info btn-lg btn-block justify-content-center"
                type="submit"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export default AddProductModal;
