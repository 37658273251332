import React from "react";
import { useTranslation } from "react-i18next";

function BlogNewsletter() {
  const { t, i18n } = useTranslation();

  return (
    <div className="widget sidebar-newsletter my-3">
      <div className="sn-icon">
        <img src="/img/icon/sn_icon.png" alt="" />
      </div>
      <div className="sn-title">
        <h4 className="title">{t("Subscribe_Newsletter")}</h4>
        <p>{t("sign_up_for_latest_news")}</p>
      </div>
      {/* <form className="sn-form">
        <input type="text" placeholder={t("Enter_Your_Email")} />
        <button className="btn">{t("subscribe")}</button>
      </form> */}
    </div>
  );
}

export default BlogNewsletter;
