import React, { useEffect, useState } from "react";
import BreadCumb from "../../../components/Breadcrumb";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PetDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [values, setValues] = useState(null);

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getPetDetail = async () => {
      const response = await axios.get(`${API_URL}pets/${params.id}`);
      if (response.data) {
        setValues(response.data);
      }
    };
    getPetDetail();
  }, [params]);

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <div>
      <BreadCumb path={t("Pet_Detail")} />
      {values && (
        <section className="breeder-details-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="breeder-details-content">
                  <h4 className="title">{values.fullname}</h4>
                  <div className="breeder-details-img">
                    <img
                      src={values.pet_image || "/img/pet/default.png"}
                      alt=""
                    />
                  </div>
                  <div className="breeder-dog-info">
                    <h5 className="title">{t("pet_Information")}</h5>
                    <div className="row">
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>{t("gender")}:</h6>
                          <span>{t(values.gender)}</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>{t("age")}:</h6>
                          <span>
                            {calculateAge(values.birthday)} {t("years")}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>Color:</h6>
                          <span>{values.color}</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>{t("size")}:</h6>
                          <span>
                            {values.size} {t("lbs")}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>{t("city")}:</h6>
                          {values.user.city && values.user.district ? (
                            <span>
                              {values.user.city} {`-${values.user.district}`}
                            </span>
                          ) : (
                            <span>{"-"}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div className="breeder-info-item">
                          <h6>{t("breed")}:</h6>
                          <span>{values.breed}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="breeder-dog-info">
                    <h5 className="title">{t("other_Information")}</h5>
                    <div className="column">
                      <div>
                        <h5>{t("medicines")}</h5>
                        <p> {values.medicine}</p>
                      </div>
                      <div>
                        <h5>{t("allergy")}</h5>
                        <p> {values.allergy}</p>
                      </div>
                      <div>
                        <h5>{t("special_cases")}</h5>
                        <p> {values.special_case}</p>
                      </div>
                    </div>
                  </div>
                  {values.desc && (
                    <div className="breeder-dog-info">
                      <h5 className="title">
                        {values.fullname} {t(`about`)}
                      </h5>
                      <p>{values.desc}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="breeder-sidebar">
                  <div className="widget sidebar-newsletter my-3">
                    <div className="sn-icon">
                      <img src="/img/icon/sn_icon.png" alt="" />
                    </div>
                    <div className="sn-title">
                      <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                      <p>{t("sign_up_for_latest_news")}</p>
                    </div>
                    {/* <form className="sn-form">
                      <input type="text" placeholder={t("Enter_Your_Email")} />
                      <button className="btn">{t("subscribe")}</button>
                    </form> */}
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default PetDetail;
