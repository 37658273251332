import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeItem, addItem, decreaseItem } from "../store/slices/cartSlice";
import $, { error } from "jquery";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState } from "react";
import Badge from "@mui/material/Badge";

function Header() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState([]);

  const handleActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.classList += " active";
    closeMenu();
  };

  const subActive = (e) => {
    document.querySelectorAll(".main-menu ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.parentNode.parentNode.classList += " active";
    closeMenu();
  };

  const closeMenu = () => {
    document.querySelector(".main-menu").classList.remove("open");
  };

  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });

      $(".mobile-user-logout").on("click", function () {
        handleLogout();
      });

      $(".mobile-provider-logout").on("click", function () {
        handleLogout();
      });
    }

    $(".navbar-toggle").on("click", function () {
      $(".navbar-nav").addClass("mobile_menu");
    });
    $(".navbar-nav li a").on("click", function () {
      $(".navbar-collapse").removeClass("show");
    });

    $(".header-search > a").on("click", function () {
      $(".search-popup-wrap").slideToggle();
      return false;
    });

    $(".search-close").on("click", function () {
      $(".search-popup-wrap").slideUp(500);
    });
    //tıklandığında menüyü kapat
    $(".mobile-menu .menu-box .menu-outer a").on("click", function () {
      $("body").removeClass("mobile-menu-visible");
    });
  }, []);

  const user = JSON.parse(localStorage.getItem("pet_user"));
  const history = useHistory();

  const handleLogout = async () => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const res = await axios.get(`${API_URL}auth/logout`, {
      headers: {
        "petapp-auth-token": token,
      },
    });
    if (res.data) {
      localStorage.removeItem("pet_user");
      localStorage.removeItem("pet_token");
      history.push("/");
      setTimeout(() => {
        window.location.reload();
        window.ReactNativeWebView.postMessage("reload");
      }, 100);
    }
  };

  useEffect(() => {
    const handleNotification = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        if (token) {
          const response = await axios.get(`${API_URL}notification`, {
            headers: {
              "petapp-auth-token": token,
            },
          });
          if (response.data) {
            setNotification(response.data);
          } else {
            console.log("Notification Error");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    handleNotification();
  }, [token]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.currentTarget.search.value;
    if (user && user.registerType === "User") {
      history.push(`/app/products?search=${searchQuery}`);
      setTimeout(() => {
        window.location.reload();
        window.ReactNativeWebView.postMessage("reload");
      }, 100);
    } else if (user && user.registerType === "Provider") {
      history.push(`/app/my_products?search=${searchQuery}`);
      setTimeout(() => {
        window.location.reload();
        window.ReactNativeWebView.postMessage("reload");
      }, 100);
    } else {
      history.push(`/products?search=${searchQuery}`);
      setTimeout(() => {
        window.location.reload();
        window.ReactNativeWebView.postMessage("reload");
      }, 100);
    }
    $(".search-popup-wrap").slideUp(500);
  };

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };

  const handleIncrease = (item) => {
    dispatch(addItem(item));
  };

  const handleDecrease = (id) => {
    dispatch(decreaseItem(id));
  };

  const isServiceExpired = (serviceEndDate) => {
    const today = new Date();

    return new Date(serviceEndDate) < today;
  };

  return (
    <header>
      <div className="header-top-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="header-top-left">
                <ul>
                  <li>{t("h_call")}: 0 (212) 269 49 50</li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="header-top-right">
                <ul className="header-top-social">
                  <li className="follow">{t("h_follow")} :</li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav show" style={{ margin: "-9px" }}>
                  <div className="logo">
                    <Link
                      to={
                        user?.registerType === "User"
                          ? "/app/user"
                          : user?.registerType === "Provider"
                          ? "/app/pro"
                          : "/"
                      }
                    >
                      <img src="/img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    {user?.registerType === "Provider" && (
                      <ul className="navigation">
                        <li>
                          <Link to="/app/pro" onClick={(e) => handleActive(e)}>
                            {t("home")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/app/pro_service_categories"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("service_categories")}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            to="/app/pro_services"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("my_services")}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="/app/pro_services"
                                onClick={(e) => subActive(e)}
                              >
                                {t("active_services")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_pending_services"
                                onClick={(e) => subActive(e)}
                              >
                                {t("pending_services")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_service_history"
                                onClick={(e) => subActive(e)}
                              >
                                {t("service_history")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_vets"
                                onClick={(e) => subActive(e)}
                              >
                                {t("vets")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_hotels"
                                onClick={(e) => subActive(e)}
                              >
                                {t("hotels")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_petshops"
                                onClick={(e) => subActive(e)}
                              >
                                {t("petshops")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        {user?.categoryServices[0]?.categoryServices?.some(
                          (service) => service.Petshop
                        ) && (
                          <li>
                            <Link
                              to="/app/my_products"
                              onClick={(e) => handleActive(e)}
                            >
                              {t("my_products")}
                            </Link>
                          </li>
                        )}
                        <li className="profile_hidden">
                          <Link to={`/app/provider/${user?._id}`}>
                            {t("my_profile")}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            to="/app/pro_settings"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("settings")}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="/app/pro_settings"
                                onClick={(e) => subActive(e)}
                              >
                                {t("general_information")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_change_password"
                                onClick={(e) => subActive(e)}
                              >
                                {t("change_password")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/pro_payment"
                                onClick={(e) => subActive(e)}
                              >
                                {t("payment_information")}
                              </Link>
                            </li>
                            {user?.categoryServices[0]?.categoryServices?.some(
                              (service) => service.Petshop
                            ) && (
                              <li>
                                <Link
                                  to="/app/petshop_info"
                                  onClick={(e) => subActive(e)}
                                >
                                  {t("petshop_informations")}
                                </Link>
                              </li>
                            )}
                          </ul>
                        </li>
                        {window.innerWidth <= 992 && (
                          <li>
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "black",
                                display: "block",
                                fontWeight: "500",
                                padding: "16px 30px",
                                textAlign: "center",
                                color: "white",
                              }}
                              onClick={() => {
                                handleLogout();
                              }}
                              className="mobile-provider-logout"
                            >
                              {t("logout")}
                            </div>
                          </li>
                        )}
                      </ul>
                    )}
                    {user?.registerType === "User" && (
                      <ul className="navigation">
                        <li>
                          <Link to="/app/user" onClick={(e) => handleActive(e)}>
                            {t("home")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/app/user_pets"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("my_pets")}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            to="/app/user_services"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("my_services")}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="/app/user_services"
                                onClick={(e) => subActive(e)}
                              >
                                {t("services")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_service_active"
                                onClick={(e) => subActive(e)}
                              >
                                {t("active_services")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_service_history"
                                onClick={(e) => subActive(e)}
                              >
                                {t("service_history")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_vets"
                                onClick={(e) => subActive(e)}
                              >
                                {t("vets")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_hotels"
                                onClick={(e) => subActive(e)}
                              >
                                {t("hotels")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_petshops"
                                onClick={(e) => subActive(e)}
                              >
                                {t("petshops")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            to="/app/products"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("f_Products")}
                          </Link>
                        </li>
                        <li className="mobile-only">
                          <Link to={"/app/user/my_orders"}>
                            {t("my_orders")}
                          </Link>
                        </li>
                        <li className="profile_hidden">
                          <Link to={`/app/user/${user?._id}`}>
                            {t("my_profile")}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            to="/app/user_settings"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("settings")}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="/app/user_settings"
                                onClick={(e) => subActive(e)}
                              >
                                {t("general_information")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/app/user_change_password"
                                onClick={(e) => subActive(e)}
                              >
                                {t("change_password")}
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                to="/app/user_payment"
                                onClick={(e) => subActive(e)}
                              >
                                {t("payment_information")}
                              </Link>
                            </li> */}
                          </ul>
                        </li>
                        {window.innerWidth <= 992 && (
                          <li>
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "black",
                                display: "block",
                                fontWeight: "500",
                                padding: "16px 30px",
                                textAlign: "center",
                                color: "white",
                              }}
                              onClick={() => {
                                handleLogout();
                              }}
                              className="mobile-user-logout"
                            >
                              {t("logout")}
                            </div>
                          </li>
                        )}
                      </ul>
                    )}
                    {!user && (
                      <ul className="navigation">
                        <li>
                          <Link to="/" onClick={(e) => handleActive(e)}>
                            {t("home")}
                          </Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link
                            to="/our_services"
                            onClick={(e) => handleActive(e)}
                          >
                            {t("f_services")}
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                to="/our_services"
                                onClick={(e) => subActive(e)}
                              >
                                {t("f_services")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/vets" onClick={(e) => subActive(e)}>
                                {t("vets")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/hotels" onClick={(e) => subActive(e)}>
                                {t("hotels")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/petshops"
                                onClick={(e) => subActive(e)}
                              >
                                {t("petshops")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/products" onClick={(e) => handleActive(e)}>
                            {t("f_Products")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/about_us" onClick={(e) => handleActive(e)}>
                            {t("f_about")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/contacts" onClick={(e) => handleActive(e)}>
                            {t("f_contacts")}
                          </Link>
                        </li>
                        <li className="login_hide">
                          <Link to="/login" onClick={(e) => handleActive(e)}>
                            {t("login_button")}
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="header-action">
                    {user ? (
                      <ul>
                        <li className="header-search">
                          <a href="/#">
                            <i className="flaticon-search" />
                          </a>
                        </li>
                        {user.registerType !== "Provider" && (
                          <li className="header-shop-cart">
                            <Link to="/app/cart">
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  marginRight: "25px",
                                  marginTop: "43px",
                                }}
                              >
                                <Badge
                                  badgeContent={cartItems.length}
                                  sx={{
                                    "& .MuiBadge-badge": {
                                      backgroundColor: "red",
                                      color: "white",
                                    },
                                  }}
                                >
                                  <ShoppingCartIcon style={{ fontSize: 30 }} />
                                </Badge>
                              </div>
                            </Link>
                            <ul className="minicart">
                              {cartItems.map((item) => (
                                <li
                                  key={item._id}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <div className="cart-img">
                                    <a href="/#">
                                      <img
                                        src={
                                          item.images[0] ||
                                          "img/product/default.png"
                                        }
                                        alt={item.name}
                                      />
                                    </a>
                                  </div>
                                  <div className="cart-content">
                                    <h4>
                                      <a href="/#">{item.name}</a>
                                    </h4>
                                    <div className="cart-price">
                                      <span className="new">{item.price}₺</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      {
                                        <div className="">
                                          {item.quantity > 1 ? (
                                            <button
                                              className="quantity-btn"
                                              onClick={() =>
                                                handleDecrease(item._id)
                                              }
                                              style={{
                                                backgroundColor: "#f5f5f5",
                                                border: "1px solid #ddd",
                                                borderRadius: "50%",
                                                width: "30px",
                                                height: "30px",
                                                fontSize: "16px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              -
                                            </button>
                                          ) : (
                                            <button
                                              onClick={() =>
                                                handleRemove(item._id)
                                              }
                                              style={{
                                                background: "none",
                                                border: "none",
                                                cursor: "pointer",
                                                color: "#ff0000",
                                                fontSize: "20px",
                                              }}
                                            >
                                              <i className="far fa-trash-alt" />
                                            </button>
                                          )}
                                          <span
                                            className="quantity-number"
                                            style={{
                                              fontSize: "16px",
                                              padding: "0 10px",
                                            }}
                                          >
                                            {item.quantity}
                                          </span>
                                          <button
                                            onClick={() => handleIncrease(item)}
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              border: "1px solid #ddd",
                                              borderRadius: "50%",
                                              width: "30px",
                                              height: "30px",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </li>
                              ))}
                              <li>
                                <div className="total-price">
                                  <span className="f-left">{t("total")}:</span>
                                  <span className="f-right">
                                    {cartItems
                                      .reduce(
                                        (total, item) =>
                                          total + item.price * item.quantity,
                                        0
                                      )
                                      .toFixed(2)}
                                    ₺
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className="checkout-link">
                                  <Link to="/app/cart">
                                    {t("shopping_cart")}
                                  </Link>
                                  {/* <Link className="black-color" to="/checkout">
                                    {t("checkout")}
                                  </Link> */}
                                </div>
                              </li>
                            </ul>
                          </li>
                        )}
                        <li className="header-notification-cart">
                          <div>
                            <img
                              src="/img/notification1.png"
                              alt="unuser"
                              style={{ borderRadius: "50px" }}
                              width={30}
                              height={50}
                            />
                          </div>
                          <ul className="minicart">
                            {notification.length > 0 ? (
                              notification.slice(0, 3).map((item, index) => (
                                <li
                                  key={index}
                                  style={{
                                    backgroundColor: "#F7F7F9",
                                    cursor: "pointer",
                                    listStyle: "none",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      t(item.message) === t("new_offer") &&
                                        isServiceExpired(item.serviceEndDate)
                                        ? `/app/user_offers/${item.serviceId}`
                                        : t(item.message) ===
                                            t("offer_updated") &&
                                          isServiceExpired(item.serviceEndDate)
                                        ? `/app/user_offers/${item.serviceId}`
                                        : t(item.message) ===
                                          t("offer_accepted")
                                        ? `/app/pro_services/${item.serviceId}`
                                        : t(item.message) ===
                                          t("provider_canceled_service")
                                        ? `/app/user_history_services/${item.serviceId}`
                                        : t(item.message) ===
                                          t("user_canceled_service")
                                        ? `/app/pro_history_services/${item.serviceId}`
                                        : t(item.message) ===
                                          t("finish_service_user")
                                        ? `/app/user_history_services/${item.serviceId}`
                                        : t(item.message) ===
                                          t("finish_service_provider")
                                        ? `/app/pro_history_services/${item.serviceId}`
                                        : null
                                    )
                                  }
                                >
                                  <div className="notification-item">
                                    <div className="notification-content">
                                      <span className="notification-title">
                                        {t(item.serviceName)}
                                      </span>
                                      <span className="notification-message">
                                        {t(item.message)}
                                      </span>
                                      <span className="notification-user">
                                        {item.pet}
                                      </span>
                                      <span className="notification-date">
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <div className="notification-item text-center">
                                <span className="notification-user">
                                  {t("no_notifications")}
                                </span>
                              </div>
                            )}
                            <li>
                              <div className="checkout-link">
                                <Link to="/app/notification">
                                  {t("see_all_notifications")}
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </li>
                        {window.innerWidth >= 992 && (
                          <li className="header-shop-cart ">
                            <div>
                              <img
                                src={
                                  user?.image ? user.image : "/img/unuser.png"
                                }
                                alt="unuser"
                                style={{
                                  borderRadius: "50px",
                                  objectFit: "cover",
                                  height: 40,
                                  width: 40,
                                }}
                                width={40}
                                height={40}
                              />
                            </div>
                            <ul className="minicart">
                              <li>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <h4 style={{ fontWeight: "700" }}>
                                    {user?.fullname}
                                  </h4>
                                  <p>{user?.email}</p>
                                </div>
                              </li>
                              <li>
                                <div className="checkout-link">
                                  {user?.registerType === "Provider" ? (
                                    <Link to={`/app/provider/${user?._id}`}>
                                      {t("my_profile")}
                                    </Link>
                                  ) : (
                                    <Link to={`/app/user/${user?._id}`}>
                                      {t("my_profile")}
                                    </Link>
                                  )}
                                  {user?.registerType === "User" && (
                                    <Link
                                      to={"/app/user/my_orders"}
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#f04336",
                                        display: "block",
                                        fontWeight: "500",
                                        padding: "16px 30px",
                                        textAlign: "center",
                                        color: "white",
                                        marginBottom: 8,
                                      }}
                                    >
                                      {t("my_orders")}
                                    </Link>
                                  )}
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor: "black",
                                      display: "block",
                                      fontWeight: "500",
                                      padding: "16px 30px",
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      handleLogout();
                                    }}
                                    className="black-color"
                                  >
                                    {t("logout")}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        )}
                      </ul>
                    ) : (
                      <ul>
                        <li className="header-search">
                          <a href="/#">
                            <i className="flaticon-search" />
                          </a>
                        </li>
                        <li className="header-shop-cart">
                          <Link to="/app/cart">
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                                marginRight: "25px",
                                marginTop: "43px",
                              }}
                            >
                              <Badge
                                badgeContent={cartItems.length}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "red",
                                    color: "white",
                                  },
                                }}
                              >
                                <ShoppingCartIcon style={{ fontSize: 30 }} />
                              </Badge>
                            </div>
                          </Link>
                          <ul className="minicart">
                            {cartItems.map((item) => (
                              <li
                                key={item._id}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <div className="cart-img">
                                  <a href="/#">
                                    <img
                                      src={
                                        item.image || "img/product/default.png"
                                      }
                                      alt={item.name}
                                    />
                                  </a>
                                </div>
                                <div className="cart-content">
                                  <h4>
                                    <a href="/#">{item.name}</a>
                                  </h4>
                                  <div className="cart-price">
                                    <span className="new">{item.price}₺</span>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    {
                                      <div className="">
                                        {item.quantity > 1 ? (
                                          <button
                                            className="quantity-btn"
                                            onClick={() =>
                                              handleDecrease(item._id)
                                            }
                                            style={{
                                              backgroundColor: "#f5f5f5",
                                              border: "1px solid #ddd",
                                              borderRadius: "50%",
                                              width: "30px",
                                              height: "30px",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            -
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              handleRemove(item._id)
                                            }
                                            style={{
                                              background: "none",
                                              border: "none",
                                              cursor: "pointer",
                                              color: "#ff0000",
                                              fontSize: "20px",
                                            }}
                                          >
                                            <i className="far fa-trash-alt" />
                                          </button>
                                        )}
                                        <span
                                          className="quantity-number"
                                          style={{
                                            fontSize: "16px",
                                            padding: "0 10px",
                                          }}
                                        >
                                          {item.quantity}
                                        </span>
                                        <button
                                          onClick={() => handleIncrease(item)}
                                          style={{
                                            backgroundColor: "#f5f5f5",
                                            border: "1px solid #ddd",
                                            borderRadius: "50%",
                                            width: "30px",
                                            height: "30px",
                                            fontSize: "16px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </li>
                            ))}
                            <li>
                              <div className="total-price">
                                <span className="f-left">{t("total")}:</span>
                                <span className="f-right">
                                  ₺
                                  {cartItems
                                    .reduce(
                                      (total, item) =>
                                        total + item.price * item.quantity,
                                      0
                                    )
                                    .toFixed(2)}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="checkout-link">
                                <Link to="/cart">{t("shopping_cart")}</Link>
                                {/* <Link className="black-color" to="/checkout">
                                  {t("checkout")}
                                </Link> */}
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="header-btn d-none d-md-block">
                          <Link to="/login" className="btn">
                            {t("login_here")}{" "}
                            <img src="/img/icon/w_pawprint.png" alt="" />
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="d-flex mt-25 justify-content-center flex-column align-items-center">
                    <Link to="/">
                      <img
                        src="/img/anuser.png"
                        style={{ width: "150px", height: "150px" }}
                        alt="user_profile"
                        className="rounded-circle"
                        title="true"
                      />
                    </Link>
                    <p>{user?.fullname}</p>
                  </div>
                  <div className="menu-outer"></div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="/#">
                          <span className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-facebook-square" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-pinterest-p" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <span className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
          </div>
        </div>
        <div
          className="header-shape"
          style={{ backgroundImage: "url('img/bg/header_shape.png')" }}
        />
      </div>

      <div
        className="search-popup-wrap"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="search-close">
          <span>
            <i className="fas fa-times" />
          </span>
        </div>
        <div className="search-wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">... {t("search_title")} ...</h2>
                <div className="search-form">
                  <form onSubmit={handleSearch}>
                    <input
                      id="search"
                      type="text"
                      name="search"
                      placeholder={t("search_text")}
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
