import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function AboutSlider() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="inner-breeder-area breeder-bg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h5 className="sub-title">{t("about_sub_title")}</h5>
              <h2 className="title">
                {t("des1")} <span>{t("des2")}</span> {t("des3")}
              </h2>
              {/* <p>
                            {t("meet_adoption-description")}
                            </p> */}
              <div className="adoption-list">
                <ul>
                  <li>
                    <i className="flaticon-tick" /> {t("tickTitle")}
                  </li>
                  <li>
                    <i className="flaticon-tick" /> {t("tickTitle2")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6">
                  <Slider className="breeder-active owl-carousel" {...settings}>
                    <div className="breeder-item">
                      <img src="/img/images/breeder_img01.jpg" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="/img/images/breeder_img02.jpg" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="/img/images/breeder_img03.jpg" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="/img/images/breeder_img04.jpg" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="/img/images/breeder_img05.jpg" alt="" />
                    </div>
                  </Slider>
                </div>
                <div className="col-md-6">
                  <div
                    className="breed-services-info"
                    style={{
                      backgroundImage: 'url("img/bg/breed_services_bg.jpg")',
                    }}
                  >
                    <h5 className="sub-title">{t("sub_desc")}</h5>
                    <h3 className="title">{t("about_title")}</h3>
                    <p>{t("about_desc")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="counter-area mt-70">
                    <HomeCounter />
                </div> */}
      </div>
    </section>
  );
}

export default AboutSlider;
