import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import StarRatings from "react-star-ratings";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ServiceHistoryForm() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [services, setServices] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const getServices = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}service/history`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        if (response.data.length > 0) {
          setServices(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, [token]);

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedService(null);
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const changeRating = (newRating, name) => {
    setRating(newRating);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const handleVote = (service) => {
    setModalVisible(true);
    setSelectedService(service);
  };

  const handleFeedback = async (e) => {
    e.preventDefault();

    if (rating === 0) {
      Swal.fire({
        icon: "error",
        title: "No Rating",
        text: "Please enter rating.",
      });
      return;
    }

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        userToProvider: {
          star: rating,
          comment: e.target.comment.value,
        },
      };

      const response = await axios.put(
        `${API_URL}service/feedback/${selectedService._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );

      if (response.data) {
        setModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your feedback has been successfully sent"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong",
      });
    }
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="shop-area pt-60 pb-60">
      {services ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="row justify-content-center">
                  {services &&
                    services.map((service, index) => (
                      <div key={service._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link
                              to={`/app/user_history_services/${service._id}`}
                            >
                              {service.job === "Care" ? (
                                <img src="/img/service/care.jpg" alt="" />
                              ) : service.job === "Training" ? (
                                <img src="/img/service/training.jpg" alt="" />
                              ) : service.job === "Hotel" ? (
                                <img src="/img/service/hotel.jpg" alt="" />
                              ) : service.job === "Group Walking" ? (
                                <img
                                  src="/img/service/group-walking.jpg"
                                  alt=""
                                />
                              ) : service.job === "Walking" ? (
                                <img src="/img/service/walking.jpg" alt="" />
                              ) : service.job === "Keeper" ? (
                                <img src="/img/service/keeper.jpg" alt="" />
                              ) : service.job === "Hotel Pension" ? (
                                <img
                                  src="/img/service/hotel-pension.jpg"
                                  alt=""
                                />
                              ) : null}
                            </Link>
                          </div>
                          {service?.feedback?.userToProvider ? (
                            <div
                              onClick={() => {
                                handleVote(service);
                              }}
                              style={{
                                position: "absolute",
                                color: "#ccc",
                                top: "3px",
                                left: "18px",
                                zIndex: "0",
                                cursor: "pointer",
                                backgroundColor: "#676666",
                                padding: "5px",
                                boxShadow: "1px 1px 5px 2px #839398",
                              }}
                            >
                              <i className="fas fa-star" /> {t("voted")}
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                handleVote(service);
                              }}
                              style={{
                                position: "absolute",
                                color: "orange",
                                top: "3px",
                                left: "18px",
                                zIndex: "0",
                                cursor: "pointer",
                                backgroundColor: "#676666",
                                padding: "5px",
                                boxShadow: "1px 1px 5px 2px #839398",
                              }}
                            >
                              <i className="fas fa-star" /> {t("vote")}
                            </div>
                          )}
                          <div className="shop-content">
                            <span>{t(service.job)}</span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <h4 className="title">
                                <Link to={`/app/user_services/${service._id}`}>
                                  {`${service.pet?.fullname}`}
                                </Link>
                                {` / `}
                                <Link
                                  to={`/app/provider/${service.offers[0].provider._id}`}
                                >
                                  {`${service.offers[0].provider.fullname}`}
                                </Link>
                              </h4>
                            </div>
                            <div className="shop-content-bottom">
                              <small>
                                {formatDate(service?.dates.startDate)}{" "}
                                {formatTime(service?.dates.startDate)} <br />
                                {formatDate(service?.dates.endDate)}{" "}
                                {formatTime(service?.dates.endDate)}
                              </small>
                              <small
                                style={{
                                  fontSize: 14,
                                  color:
                                    service.offers[0].status === 3
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {service.offers[0].status === 3 &&
                                  t("completed")}
                                {service.offers[0].status === 4 &&
                                  t("canceled_by_user")}
                                {service.offers[0].status === 5 &&
                                  t("canceled_by_provider")}
                              </small>
                            </div>
                            <div>
                              <Modal
                                open={modalVisible}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className="col-10 col-lg-6" sx={boxStyle}>
                                  <div
                                    onClick={handleCloseModal}
                                    style={{
                                      position: "absolute",
                                      cursor: "pointer",
                                      top: 5,
                                      right: 5,
                                      zIndex: 9999,
                                    }}
                                  >
                                    <img
                                      src="/img/close.png"
                                      alt="close_button"
                                      width={40}
                                      height={40}
                                    />
                                  </div>
                                  {selectedService?.feedback?.userToProvider ? (
                                    <div>
                                      <h4
                                        className="my-2"
                                        style={{
                                          borderBottom: "1px solid #ccc",
                                          fontWeight: "700",
                                          color: "#0a303a",
                                          textAlign: "center",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        {t("your_vote")}
                                      </h4>
                                      <div className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center">
                                        <StarRatings
                                          rating={
                                            selectedService?.feedback
                                              ?.userToProvider.star
                                          }
                                          starHoverColor="orange"
                                          starRatedColor="orange"
                                          starDimension="32px"
                                          changeRating={changeRating}
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                        <textarea
                                          name="comment"
                                          id="comment"
                                          defaultValue={
                                            selectedService?.feedback
                                              ?.userToProvider.comment
                                          }
                                          style={{
                                            resize: "none",
                                            marginTop: "20px",
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: "8px",
                                            border: "1px solid #D3D3D3",
                                          }}
                                        ></textarea>
                                      </div>
                                    </div>
                                  ) : (
                                    <form onSubmit={handleFeedback}>
                                      <div className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center">
                                        <StarRatings
                                          rating={rating}
                                          starHoverColor="orange"
                                          starRatedColor="orange"
                                          starDimension="32px"
                                          changeRating={changeRating}
                                          numberOfStars={5}
                                          name="rating"
                                        />
                                        <Typography
                                          style={{ textAlign: "center" }}
                                          className="px-2 mt-5 mb-2"
                                        >
                                          {t(
                                            "please_write_your_thoughts_about"
                                          )}
                                        </Typography>
                                        <textarea
                                          name="comment"
                                          id="comment"
                                          style={{
                                            resize: "none",
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: "8px",
                                            border: "1px solid #D3D3D3",
                                          }}
                                        ></textarea>
                                        <div className="pt-1 w-100">
                                          <button
                                            className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                                            type="submit"
                                          >
                                            {t("save")}
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  )}
                                </Box>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src="/img/category/cross.png"
              alt=""
              width={"200px"}
              height={"200px"}
            />
            <p>{t("no_data")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceHistoryForm;
