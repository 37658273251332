import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Modal, Box, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getPets } from "../../../../store/slices/petSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

function ServiceComponent() {
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const dispatch = useDispatch();
  const pets = useSelector((state) => state.pet.pets);
  const [services, setServices] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState(null);
  const [editService, setEditService] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (modalVisible) {
      dispatch(getPets(token));
    }
  }, [dispatch, modalVisible, token]);

  useEffect(() => {
    if (editModalVisible) {
      dispatch(getPets(token));
    }
  }, [dispatch, editModalVisible, token]);

  useEffect(() => {
    const checkPet = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}pets/check-pet`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        if (!response.data.status) {
          getServices();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getServices = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}service`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        if (response.data) {
          setServices(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkPet();
  }, [token]);

  const handleEdit = async (service) => {
    setEditService(service);
    setStartDate(new Date(service.dates.startDate));
    setEndDate(new Date(service.dates.endDate));
    await handleSelectedPet(service.pet._id);
    setEditModalVisible(true);
  };

  const handleDelete = async (serviceId) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      Swal.fire({
        icon: "warning",
        text: "Do you want to delete service?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.delete(
            `${API_URL}service/${serviceId}`,
            {
              headers: {
                "petapp-auth-token": token,
              },
            }
          );
          if (response.data) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        } else if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  const handleCloseModal = () => {
    setModalVisible(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setEditService(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleAddService = (e) => {
    setModalVisible(true);
  };

  const handleSelectedPet = async (petId) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(`${API_URL}pets/${petId}`, {
        headers: {
          "petapp-auth-token": token,
        },
      });

      let servicesResponse;
      if (response.data.type === "Dog") {
        servicesResponse = await axios.get(`${API_URL}jobs/Dog`);
      } else if (response.data.type === "Cat") {
        servicesResponse = await axios.get(`${API_URL}jobs/Cat`);
      } else if (response.data.type === "Bird") {
        servicesResponse = await axios.get(`${API_URL}jobs/Bird`);
      }
      setSelectedServices(servicesResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddServiceForm = async (e) => {
    e.preventDefault();

    const payload = {
      pet: e.target.pet.value,
      job: e.target.service.value,
      dates: {
        startDate: startDate,
        endDate: endDate,
      },
      desc: e.target.desc.value,
      //dates: selectedDates
    };

    if (startDate > endDate) {
      setModalVisible(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date must be greater than start date",
      });
      return;
    }
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.post(`${API_URL}service`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        setModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your service has been successfully added"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      setModalVisible(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleEditServiceForm = async (e) => {
    e.preventDefault();

    const payload = {
      pet: e.target.edit_pet.value,
      job: e.target.edit_service.value,
      dates: {
        startDate: startDate,
        endDate: endDate,
      },
      desc: e.target.edit_desc.value,
      //dates: selectedDates
    };
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      if (startDate > endDate) {
        setEditModalVisible(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "End date must be greater than start date",
        });
        return;
      }

      const response = await axios.put(
        `${API_URL}service/${editService._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setEditModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your service has been successfully updated"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      setEditModalVisible(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const isCompleted = (endDate) => {
    const now = new Date();
    return new Date(endDate) < now;
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="shop-area pt-60 pb-60">
      {services && user.district && (
        <div ref={dropdownRef} className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6">
                    <div style={{ margin: "10px", position: "relative" }}>
                      <img
                        onClick={() => {
                          handleAddService();
                        }}
                        src="/img/plus.png"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                      <div className="adoption-shop-content">
                        <h4 className="title">{t("new_service")}</h4>
                        <div className="adoption-meta">
                          <ul>
                            <li>
                              <i className="fas fa-dog" />
                              {t("walker_care_trainer")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {services &&
                    services.map((service, index) => (
                      <div key={service._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link to={`/app/user_services/${service._id}`}>
                              {service.job === "Care" ? (
                                <img src="/img/service/care.jpg" alt="" />
                              ) : service.job === "Training" ? (
                                <img src="/img/service/training.jpg" alt="" />
                              ) : service.job === "Hotel" ? (
                                <img src="/img/service/hotel.jpg" alt="" />
                              ) : service.job === "Group Walking" ? (
                                <img
                                  src="/img/service/group-walking.jpg"
                                  alt=""
                                />
                              ) : service.job === "Walking" ? (
                                <img src="/img/service/walking.jpg" alt="" />
                              ) : service.job === "Keeper" ? (
                                <img src="/img/service/keeper.jpg" alt="" />
                              ) : service.job === "Hotel Pension" ? (
                                <img
                                  src="/img/service/hotel-pension.jpg"
                                  alt=""
                                />
                              ) : null}
                            </Link>
                            <div
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "10px",
                                width: "42px",
                                height: "42px",
                                backgroundColor: "#f04336",
                                color: "white",
                                padding: "0 14px",
                                borderRadius: "50%",
                                lineHeight: "42px",
                              }}
                            >
                              <i
                                className="fa fa-bars"
                                style={{ cursor: "pointer" }}
                                aria-hidden="true"
                                onClick={() => handleDropdownToggle(index)}
                              ></i>
                              {dropdownIndex === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "0px",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <Button
                                        style={{ color: "blue" }}
                                        onClick={() => {
                                          handleEdit(service);
                                        }}
                                        className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                      >
                                        <Edit
                                          style={{
                                            color: "blue",
                                            marginRight: "3px",
                                          }}
                                        />
                                        {t("edit")}
                                      </Button>
                                    </li>
                                    <li>
                                      <Button
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          handleDelete(service._id);
                                        }}
                                        className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                      >
                                        <Delete
                                          style={{
                                            color: "red",
                                            marginRight: "3px",
                                          }}
                                        />
                                        {t("delete")}
                                      </Button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            {service.offers?.length > 0 ? (
                              <Link to={`/app/user_offers/${service._id}`}>
                                <div className="offer-container">
                                  <i className="fas fa-user" />{" "}
                                  {service.offers?.length} {t("offer")}
                                </div>
                              </Link>
                            ) : (
                              <div
                                style={{
                                  position: "absolute",
                                  color: "#ccc",
                                  top: "3px",
                                  left: "18px",
                                  zIndex: "0",
                                  cursor: "pointer",
                                  backgroundColor: "#676666",
                                  padding: "5px",
                                  boxShadow: "1px 1px 5px 2px #839398",
                                }}
                              >
                                <i className="fas fa-user" />{" "}
                                {service.offers?.length} {t("offer")}
                              </div>
                            )}
                          </div>
                          <div className="shop-content">
                            <span>{t(service.job)}</span>
                            <h4 className="title">
                              <Link to={`/app/user_services/${service._id}`}>
                                {service.pet?.fullname}
                              </Link>
                            </h4>
                            <div className="shop-content-bottom">
                              <small>
                                {formatDate(service?.dates.startDate)}{" "}
                                {formatTime(service?.dates.startDate)} <br />
                                {formatDate(service?.dates.endDate)}{" "}
                                {formatTime(service?.dates.endDate)}
                              </small>
                              <small style={{ color: "orange" }}>
                                {isCompleted(service.dates.endDate)
                                  ? t("expired")
                                  : t("Pending")}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div>
                    <Modal
                      open={modalVisible}
                      onClose={handleCloseModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="col-10 col-lg-6" sx={boxStyle}>
                        <form
                          className="contact-form"
                          onSubmit={handleAddServiceForm}
                          style={{
                            overflowY: "scroll",
                            height: "-webkit-fill-available",
                          }}
                        >
                          <div
                            onClick={handleCloseModal}
                            className="modalClose"
                          >
                            <img
                              src="/img/close.png"
                              alt="close_button"
                              width={40}
                              height={40}
                            />
                          </div>
                          <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                            <div className="d-flex flex-row flex-wrap justify-content-center w-100 px-3">
                              <div className="d-flex col-md-10 col flex-column">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "black",
                                    marginTop: "10px",
                                  }}
                                >
                                  {t("my_pets")}
                                </Typography>
                                <select
                                  required
                                  style={{
                                    padding: "8px",
                                    backgroundColor: "#637579",
                                    borderRadius: "5px",
                                    marginBlock: "5px",
                                  }}
                                  id="pet"
                                  className="form-select btn"
                                  defaultValue=""
                                  onChange={(e) => {
                                    handleSelectedPet(e.target.value);
                                  }}
                                  aria-label="Default select example"
                                >
                                  <option
                                    style={{ backgroundColor: "#0a313b" }}
                                    value={""}
                                    disabled
                                  >
                                    {t("select")}
                                  </option>
                                  {pets &&
                                    pets.map((pet) => (
                                      <option
                                        style={{ backgroundColor: "#0a313b" }}
                                        key={pet._id}
                                        value={pet._id}
                                      >
                                        {pet.fullname}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="d-flex col-md-10 col flex-column">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "black",
                                    marginTop: "10px",
                                  }}
                                >
                                  {t("services")}
                                </Typography>
                                <select
                                  required
                                  style={{
                                    backgroundColor: "#637579",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    marginBlock: "5px",
                                  }}
                                  id="service"
                                  className="form-select btn"
                                  defaultValue={""}
                                  aria-label="Default select example"
                                >
                                  <option
                                    style={{ backgroundColor: "#0a313b" }}
                                    disabled
                                    value={""}
                                  >
                                    {t("select")}
                                  </option>
                                  {selectedServices &&
                                    selectedServices.map((service, index) => (
                                      <option
                                        key={index}
                                        value={service}
                                        style={{ backgroundColor: "#0a313b" }}
                                      >
                                        {t(service)}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div>
                              <div className="d-flex justify-content-center align-items-center">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "black",
                                    marginTop: "10px",
                                  }}
                                >
                                  {t("service_date")}
                                </Typography>
                              </div>
                              <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                                <div
                                  style={{
                                    border: "1px solid black",
                                    padding: 10,
                                    margin: 10,
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="mb-2">
                                    <Typography
                                      sx={{ fontWeight: "400", color: "black" }}
                                    >
                                      {t("start_time")}
                                    </Typography>
                                    <DatePicker
                                      required
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      showTimeSelect
                                      timeFormat="p"
                                      timeIntervals={30}
                                      dateFormat="dd/MM/yyyy HH:mm"
                                    />
                                  </div>
                                  <div>
                                    <Typography
                                      sx={{ fontWeight: "400", color: "black" }}
                                    >
                                      {t("end_time")}
                                    </Typography>
                                    <DatePicker
                                      required
                                      selected={endDate}
                                      onChange={(date) => setEndDate(date)}
                                      showTimeSelect
                                      timeFormat="p"
                                      timeIntervals={30}
                                      dateFormat="dd/MM/yyyy HH:mm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex col-md-10 col justify-content-center flex-column">
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "black",
                                  marginTop: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {t("special_cases")}
                                <span
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  {t("max_char")}
                                </span>
                              </Typography>
                              <textarea
                                name="desc"
                                id="desc"
                                style={{
                                  resize: "none",
                                  height: "200px",
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <button
                              style={{ width: "80%", justifyContent: "center" }}
                              className="btn"
                            >
                              {t("save")}
                            </button>
                          </div>
                        </form>
                      </Box>
                    </Modal>
                  </div>
                  <div>
                    {editService && (
                      <div>
                        <Modal
                          open={editModalVisible}
                          onClose={handleCloseEditModal}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className="col-10 col-lg-6" sx={boxStyle}>
                            <form
                              className="contact-form"
                              onSubmit={handleEditServiceForm}
                              style={{
                                overflowY: "scroll",
                                height: "-webkit-fill-available",
                              }}
                            >
                              <div
                                onClick={handleCloseEditModal}
                                className="modalClose"
                              >
                                <img
                                  src="/img/close.png"
                                  alt="close_button"
                                  width={40}
                                  height={40}
                                />
                              </div>
                              <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                                <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                                  <div className="d-flex col-md-10 col flex-column">
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {t("my_pets")}
                                    </Typography>
                                    <select
                                      required
                                      style={{
                                        padding: "8px",
                                        backgroundColor: "#637579",
                                        borderRadius: "5px",
                                        marginBlock: "5px",
                                      }}
                                      id="edit_pet"
                                      className="form-select btn"
                                      defaultValue={editService.pet.fullname}
                                      aria-label="Default select example"
                                    >
                                      <option
                                        style={{ backgroundColor: "#0a313b" }}
                                        disabled
                                        value={""}
                                      >
                                        {t("select")}
                                      </option>
                                      {pets &&
                                        pets.map((pet) => (
                                          <option
                                            style={{
                                              backgroundColor: "#0a313b",
                                            }}
                                            key={pet._id}
                                            value={pet._id}
                                          >
                                            {pet.fullname}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="d-flex col-md-10 col flex-column">
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {t("services")}
                                    </Typography>
                                    <select
                                      required
                                      style={{
                                        backgroundColor: "#637579",
                                        padding: "8px",
                                        borderRadius: "5px",
                                        marginBlock: "5px",
                                      }}
                                      id="edit_service"
                                      className="form-select btn"
                                      defaultValue={editService.job}
                                      aria-label="Default select example"
                                    >
                                      <option
                                        style={{ backgroundColor: "#0a313b" }}
                                        disabled
                                        value={""}
                                      >
                                        {t("select")}
                                      </option>
                                      {selectedServices &&
                                        selectedServices.map(
                                          (service, index) => (
                                            <option
                                              key={index}
                                              value={service}
                                              style={{
                                                backgroundColor: "#0a313b",
                                              }}
                                            >
                                              {t(service)}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                </div>
                                <div>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {t("service_date")}
                                    </Typography>
                                  </div>
                                  <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                                    <div
                                      style={{
                                        border: "1px solid black",
                                        padding: 10,
                                        margin: 10,
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <div className="mb-2">
                                        <Typography
                                          sx={{
                                            fontWeight: "400",
                                            color: "black",
                                          }}
                                        >
                                          {t("start_time")}
                                        </Typography>
                                        <DatePicker
                                          required
                                          selected={startDate}
                                          onChange={(date) =>
                                            setStartDate(date)
                                          }
                                          showTimeSelect
                                          timeFormat="p"
                                          timeIntervals={30}
                                          dateFormat="dd/MM/yyyy HH:mm"
                                        />
                                      </div>
                                      <div>
                                        <Typography
                                          sx={{
                                            fontWeight: "400",
                                            color: "black",
                                          }}
                                        >
                                          {t("end_time")}
                                        </Typography>
                                        <div>
                                          <DatePicker
                                            required
                                            selected={endDate}
                                            onChange={(date) =>
                                              setEndDate(date)
                                            }
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={30}
                                            dateFormat="dd/MM/yyyy HH:mm"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex col-md-10 col justify-content-center flex-column">
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: "black",
                                      marginTop: "10px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      gap: "5px",
                                    }}
                                  >
                                    {t("special_cases")}
                                    <span
                                      style={{ fontSize: "12px", color: "red" }}
                                    >
                                      {t("max_char")}
                                    </span>
                                  </Typography>
                                  <textarea
                                    name="edit_desc"
                                    id="edit_desc"
                                    defaultValue={editService?.desc}
                                    style={{
                                      resize: "none",
                                      height: "200px",
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <button
                                  style={{
                                    width: "80%",
                                    justifyContent: "center",
                                  }}
                                  className="btn"
                                >
                                  {t("save")}
                                </button>
                              </div>
                            </form>
                          </Box>
                        </Modal>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!services && (
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <img
            src="/img/category/veterinary.png"
            alt=""
            width={"100"}
            height={"100px"}
          />
          <p style={{ marginTop: "20px" }}>
            {t("servicecomponent_p")}{" "}
            <span>
              <Link
                style={{ color: "orange", fontWeight: "bold" }}
                to="/app/user_pets"
              >
                {t("MyPets")}
              </Link>
            </span>{" "}
            {t("menu_and_add_you_pet")}
          </p>
        </div>
      )}
      {!user.district && (
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <img
            src="/img/category/map.png"
            alt=""
            width={"100px"}
            height={"100px"}
          />
          <p style={{ marginTop: "20px" }}>
            {t("In_order")}{" "}
            <span>
              <Link
                style={{ color: "orange", fontWeight: "bold" }}
                to="/app/user_settings"
              >
                {t("General_informations")}
              </Link>
            </span>{" "}
            {t("Menu_add")}
          </p>
        </div>
      )}
    </div>
  );
}

export default ServiceComponent;
