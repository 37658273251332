import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "react-switch";
import BreadCumb from "../../components/Breadcrumb";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { Modal, Typography, Box, Button, TextField } from "@mui/material";

function Register() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [type, setType] = useState(true);
  const [phone, setPhone] = useState("+90");
  const [openModal, setOpenModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const handleChangeRegisterType = (e) => {
    setType(e);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isValidPhoneNumber(phone)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number.",
      });
      return;
    }

    if (!validatePassword(e.currentTarget.password.value)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must be at least 6 characters long, contain at least one uppercase letter and one number.",
      });
      return;
    }

    try {
      const payload = {
        fullname: e.currentTarget.fullname.value,
        email: e.currentTarget.email.value,
        phone: phone,
        registerType: type ? "User" : "Provider",
        password: e.currentTarget.password.value,
        confirm_password: e.currentTarget.confirm_password.value,
      };

      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.post(`${API_URL}auth/signup`, payload);

      if (res.data) {
        setEmail(e.target.elements.email.value);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t(
            "Registration successful. Please enter the confirmation code sent to your e-mail address to continue"
          ),
        }).then((result) => {
          if (result.isConfirmed) {
            setOpenModal(true);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.post(`${API_URL}auth/verify-email`, {
        verificationCode: verificationCode,
        email: email,
      });

      if (res.data) {
        setOpenModal(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("E-mail verification is success"),
        }).then(() => {
          setOpenModal(false);
          history.push("/login");
        });
      }
    } catch (error) {
      console.log(error);
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div>
      <BreadCumb path={t("register")} />
      <div className="container">
        <section className="my-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-black">
                <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-xl-0 mt-15">
                  <form
                    className="contact-form"
                    onSubmit={handleRegister}
                    style={{ width: "23rem" }}
                  >
                    <div className="form-outline mb-4 d-flex flex-column align-items-center justify-content-center">
                      <label className="form-label" htmlFor="register_type">
                        {t("register_type")}
                      </label>
                      <div
                        className={`toggle-container ${type ? "" : "right"}`}
                      >
                        <div
                          className={`toggle-option ${
                            type ? "selected" : "unselected"
                          }`}
                          onClick={() => handleChangeRegisterType(true)}
                        >
                          {t("user_service")}
                        </div>
                        <div
                          className={`toggle-option ${
                            type ? "unselected" : "selected"
                          }`}
                          onClick={() => handleChangeRegisterType(false)}
                        >
                          {t("provider_service")}
                        </div>
                        <div className="toggle-slider"></div>
                      </div>
                      <span className="register-type-span">
                        <strong>
                          {type
                            ? t("Getting_Service1")
                            : t("service_provider1")}
                        </strong>{" "}
                        {type ? t("Getting_Service") : t("service_provider")}
                      </span>
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="fullname">
                        {t("fullname")}
                      </label>
                      <input required type="text" id="fullname" />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="email">
                        {t("email")}
                      </label>
                      <input required type="email" id="email" />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="phone">
                        {t("phone")}
                      </label>
                      <PhoneInput
                        required
                        international
                        defaultCountry="TR"
                        value={phone}
                        onChange={setPhone}
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="password">
                        {t("login_password")}
                      </label>
                      <input required type="password" id="password" />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="confirm_password">
                        {t("confirm_password")}
                      </label>
                      <input required type="password" id="confirm_password" />
                    </div>
                    <div className="pt-1 mb-4">
                      <button
                        className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                        type="submit"
                      >
                        {t("register")}
                      </button>
                    </div>
                    <p>
                      {t("you_already_have_an_account")}{" "}
                      <Link to="/login" className="link-info">
                        {t("login_here")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <div className="align-items-center justify-content-center d-flex">
                <img
                  src="/img/blog/blog_details_img01.jpg"
                  alt="Register"
                  className="w-auto rounded"
                  style={{
                    objectFit: "cover",
                    objectPosition: "left",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Lütfen e-posta adresinize gelen 6 haneli kodu giriniz.
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="6 Haneli Kod"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            {error && <Typography color="error">{t(error)}</Typography>}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleVerifyEmail}
            >
              Onayla
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Register;
