import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";

function LostDetail() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [values, setValues] = useState(null);
  const [isCheckbox, setIsCheckbox] = useState(false);

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getLostDetail = async () => {
      const response = await axios.get(`${API_URL}lost/detail/${params.id}`);
      if (response.data) {
        setValues(response.data);
      }
    };
    getLostDetail();
  }, [params]);

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleSubmitLost = (e) => {
    e.preventDefault();

    try {
      const payload = {
        desc: e.currentTarget.comment.value,
      };
      console.log(payload);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="breeder-details-area">
      {values && (
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="breeder-details-content">
                <h4 className="title">{values.fullname}</h4>
                <div className="breeder-details-img">
                  <img
                    src={values.image_url || "/img/lost_default.png"}
                    className="w-100"
                    alt=""
                  />
                </div>
                <p>{values.desc}</p>
                <div className="breeder-dog-info">
                  <h5 className="title">{t("pet_Information")}</h5>
                  <div className="row">
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("gender")}:</h6>
                        <span>{values.gender}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("age")}:</h6>
                        <span>
                          {calculateAge(values.birthday)} {t("years")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("color")}:</h6>
                        <span>{values.color}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("size")}:</h6>
                        <span>
                          {values.size} {t("lbs")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("city")}:</h6>
                        <span>New York</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("breed")}:</h6>
                        <span>{values.breed}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="breeder-sidebar">
                <div className="widget breeder-widget">
                  <div className="breeder-widget-title mb-20">
                    <h5 className="title">{t("lotus_found_request_form")}</h5>
                  </div>
                  <form
                    onSubmit={handleSubmitLost}
                    className="sidebar-find-pets"
                  >
                    <textarea
                      name="comment"
                      id="comment"
                      placeholder="Açıklama"
                      style={{
                        resize: "none",
                        width: "100%",
                        height: "100px",
                        borderRadius: "8px",
                        border: "1px solid #D3D3D3",
                      }}
                    ></textarea>
                    <div
                      className="form-grp"
                      style={{
                        display: "flex",
                        alignItems: "start",
                        marginBottom: "5px",
                      }}
                    >
                      <Checkbox
                        checked={isCheckbox}
                        onChange={(e) => setIsCheckbox(e.target.checked)}
                      />
                      <p
                        style={{
                          fontSize: 14,
                          textAlign: "left",
                          marginLeft: "8px",
                        }}
                      >
                        {t("lost_desc")}
                      </p>
                    </div>
                    <button disabled={!isCheckbox} className="btn">
                      {t("send")}
                    </button>
                  </form>
                </div>
                {/* <div className="widget sidebar-newsletter my-3">
                  <div className="sn-icon">
                    <img src="/img/icon/sn_icon.png" alt="" />
                  </div>
                  <div className="sn-title">
                    <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                    <p>{t("Sign_up_For_Latest_News")}</p>
                  </div>
                  <form className="sn-form">
                    <input type="text" placeholder={t("Enter_Your_Email")} />
                    <button className="btn">{t("newsletter_button")}</button>
                  </form>
                </div> */}
              </aside>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default LostDetail;
