import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const ProChangePasswordForm = () => {
  const { t, i18n } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const handlePassword = async (e) => {
    e.preventDefault();
    const npass = e.currentTarget.npassword.value;
    const cnpass = e.currentTarget.cnpassword.value;

    if (npass !== cnpass) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "New password and confirm password do not match",
      });
      return;
    }
    if (npass.length < 6 || cnpass.length < 6) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Password must be at least 6 characters",
      });
      return;
    }

    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const payload = {
      oldPassword: e.currentTarget.password.value,
      newPassword: e.currentTarget.npassword.value,
      confirmNewPassword: e.currentTarget.cnpassword.value,
    };

    try {
      const res = await axios.post(`${API_URL}users/change_password`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });

      if (res.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your password has been successfully changed"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };
  return (
    <div>
      <div>
        <form
          className="contact-form px-4"
          onSubmit={handlePassword}
          style={{ width: "23rem" }}
        >
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("current_password")}
            </label>
            <input
              required
              type="password"
              id="password"
              className="form-control"
            />
          </div>
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("new_password")}
            </label>
            <input
              required
              type="password"
              id="npassword"
              className="form-control"
            />
          </div>
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("new_password_confirm")}
            </label>
            <input
              required
              type="password"
              id="cnpassword"
              className="form-control"
            />
          </div>
          <div className="pt-1 mb-4">
            <button
              className="btn btn-info btn-lg btn-block d-flex justify-content-center"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProChangePasswordForm;
