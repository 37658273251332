import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Box, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import MultiSelect from "multiselect-react-dropdown";
import { Cancel, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function ServiceCategoryForm() {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    values: null,
    id: null,
  });
  const [jobs, setJobs] = useState(null);
  const [selectedPet, setSelectedPet] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [allCategories, setAllCategories] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const getServices = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}category`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        setAllCategories(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, [token]);

  useEffect(() => {
    const getJobs = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}jobs`);
        setJobs(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getJobs();
  }, [modalVisible]);

  const handleOpenEdit = async (id, category) => {
    const pet = Object.keys(category)[0];
    setEditModalVisible(true);
    setSelectedCategory({
      values: category,
      id: id,
    });
    handlePetSelection(pet);

    const translatedServices = (category[pet] || []).map((service) =>
      t(service)
    );
    setServices(translatedServices);
  };

  const handlePetSelection = async (pet) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(`${API_URL}jobs/${pet}`);
      setSelectedPet(pet);
      setSelectedServices(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setEditModalVisible(false);
    setSelectedCategory(null);
  };

  const handleAddService = async (e) => {
    e.preventDefault();

    if (services.length === 0) {
      setModalVisible(false);
      Swal.fire({
        icon: "error",
        title: "No Service Selected",
        text: "Please select at least one service.",
      });
      return;
    }

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const data = {
        categoryServices: { [selectedPet]: services },
      };

      const response = await axios.post(`${API_URL}category`, data, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        setModalVisible(false);
        localStorage.setItem("pet_user", JSON.stringify(response.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your service category has been successfully saved"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      // Hata durumunda uygun bir geri bildirim gösterebilirsiniz.
    }
  };

  const handleEditService = async (e) => {
    e.preventDefault();

    if (services.length === 0) {
      setEditModalVisible(false);
      Swal.fire({
        icon: "error",
        title: "No Service Selected",
        text: "Please select at least one service.",
      });
      return;
    }
    const englishServices = services.map(
      (service) => reverseTranslate(service) || service
    );
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const data = {
        categoryServices: { [selectedPet]: englishServices },
      };

      const response = await axios.put(
        `${API_URL}category/${selectedCategory.id}/${Object.keys(
          selectedCategory.values
        ).toString()}`,
        data,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setEditModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your service category has been successfully saved"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
      setEditModalVisible(false);
    }
  };

  const handleDelete = async (id, pet) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      Swal.fire({
        icon: "warning",
        text: "Do you want to delete the service category?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.delete(
            `${API_URL}category/${id}/${pet}`,
            {
              headers: {
                "petapp-auth-token": token,
              },
            }
          );
          if (response.data) {
            localStorage.setItem("pet_user", JSON.stringify(response.data));
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        }
        if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 2,
    overflowY: "auto",
  };

  const availableCategories =
    allCategories &&
    allCategories.categoryServices.flatMap((category) => Object.keys(category));
  const newCategoryOptions = jobs ? Object.keys(jobs[0]) : [];
  const filteredOptions = newCategoryOptions.filter(
    (option) => !availableCategories?.includes(option)
  );

  const reverseTranslate = (value) => {
    const translations = {
      Care: "Care",
      Bakım: "Care",
      Training: "Training",
      Eğitim: "Training",
      Walking: "Walking",
      Yürüyüş: "Walking",
      Keeper: "Keeper",
      Bakıcı: "Keeper",
      "Grup Yürüyüşü": "Group Walking",
      "Group Walking": "Group Walking",
      Hotel: "Hotel",
      Satış: "Sales",
      Sales: "Sales",
      "Petshop Sales": "Petshop Sales",
      "Petshop Satış": "Petshop Sales",
    };
    return translations[value] || value;
  };

  const translatedOptions = selectedServices.map((option) => t(option));

  return (
    <div className="shop-area pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="shop-wrap">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div style={{ margin: "10px", position: "relative" }}>
                    <img
                      onClick={() => {
                        setModalVisible(true);
                      }}
                      src="/img/plus.png"
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                    <div className="adoption-shop-content">
                      <h4 className="title">{t("new_service")}</h4>
                      <div className="adoption-meta">
                        <ul>
                          <li>
                            <i className="fas fa-dog" />
                            {t("walker_care_trainer")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {allCategories &&
                  allCategories.categoryServices.map((category, index) => (
                    <div key={index} className="col-lg-4 col-sm-6">
                      <div className="shop-item mb-55">
                        <div className="shop-thumb">
                          {Object.keys(category)[0] === "Dog" ? (
                            <img src="/img/pet/dog.png" alt="Dog" />
                          ) : Object.keys(category)[0] === "Cat" ? (
                            <img src="/img/pet/cat.png" alt="Cat" />
                          ) : Object.keys(category)[0] === "Bird" ? (
                            <img src="/img/pet/bird.png" alt="Bird" />
                          ) : Object.keys(category)[0] === "Petshop" ? (
                            <img src="/img/pet/petshop.png" alt="Petshop" />
                          ) : null}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "10px",
                            width: "42px",
                            height: "42px",
                            backgroundColor: "#f04336",
                            color: "white",
                            padding: "0 14px",
                            borderRadius: "50%",
                            lineHeight: "42px",
                          }}
                        >
                          <i
                            className="fa fa-bars"
                            style={{ cursor: "pointer" }}
                            aria-hidden="true"
                            onClick={() => handleDropdownToggle(index)}
                          ></i>
                          {dropdownIndex === index && (
                            <div
                              ref={dropdownRef}
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "0px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                padding: "5px",
                              }}
                            >
                              <ul>
                                <li>
                                  <Button
                                    style={{ color: "blue" }}
                                    onClick={() => {
                                      handleOpenEdit(
                                        allCategories._id,
                                        category
                                      );
                                    }}
                                    className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                  >
                                    <Edit
                                      style={{
                                        color: "blue",
                                        marginRight: "3px",
                                      }}
                                    />
                                    {t("edit")}
                                  </Button>
                                </li>
                                <li>
                                  <Button
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      handleDelete(
                                        allCategories._id,
                                        Object.keys(category).toString()
                                      );
                                    }}
                                    className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                  >
                                    <Cancel
                                      style={{
                                        color: "red",
                                        marginRight: "3px",
                                      }}
                                    />
                                    {t("delete")}
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          {Object.entries(category).map(([pet, services]) => (
                            <div key={pet}>
                              <h4 className="title">
                                <span>{t(pet)}</span>
                              </h4>
                              <div className="shop-content">
                                {services.map((service, idx) => (
                                  <span key={idx}>{t(service)}</span>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                <div>
                  <Modal
                    open={modalVisible}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="col-10 col-lg-6" sx={boxStyle}>
                      <form
                        className="contact-form"
                        onSubmit={handleAddService}
                      >
                        <div
                          onClick={handleCloseModal}
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            top: 5,
                            right: 5,
                            zIndex: 9999,
                          }}
                        >
                          <img
                            src="/img/close.png"
                            alt="close_button"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                          <div className="d-flex col-md-10 col flex-column">
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                              }}
                            >
                              {t("service_type")}
                            </Typography>
                            <select
                              required
                              style={{
                                padding: "8px",
                                backgroundColor: "#637579",
                                borderRadius: "5px",
                                marginBlock: "5px",
                              }}
                              id="pet"
                              className="form-select btn"
                              defaultValue={""}
                              aria-label="Default select example"
                              onChange={(e) =>
                                handlePetSelection(e.target.value)
                              }
                            >
                              <option
                                style={{ backgroundColor: "#0a313b" }}
                                value={""}
                                disabled
                              >
                                {t("select")}
                              </option>
                              {filteredOptions.map((option, index) => (
                                <option
                                  key={index}
                                  style={{ backgroundColor: "#0a313b" }}
                                  value={option}
                                >
                                  {t(option)}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="d-flex col-md-10 col flex-column">
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                marginTop: "10px",
                              }}
                            >
                              {t("services")}
                            </Typography>
                            <MultiSelect
                              isObject={false}
                              style={{
                                option: {
                                  color: "#fff",
                                  background: "black",
                                },
                              }}
                              onSelect={(value) => {
                                setServices(value.map(reverseTranslate));
                              }}
                              placeholder=""
                              selectionLimit={3}
                              options={translatedOptions}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            style={{ width: "80%", justifyContent: "center" }}
                            className="btn"
                          >
                            {t("save")}
                          </button>
                        </div>
                      </form>
                    </Box>
                  </Modal>
                </div>
                {editModalVisible && selectedCategory && (
                  <div>
                    <Modal
                      open={editModalVisible}
                      onClose={handleCloseEditModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="col-10 col-lg-6" sx={boxStyle}>
                        <form
                          className="contact-form"
                          onSubmit={handleEditService}
                        >
                          <div
                            onClick={handleCloseEditModal}
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              top: 5,
                              right: 5,
                              zIndex: 9999,
                            }}
                          >
                            <img
                              src="/img/close.png"
                              alt="close_button"
                              width={40}
                              height={40}
                            />
                          </div>
                          <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                            <div className="d-flex col-md-10 col flex-column">
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "black",
                                  marginTop: "10px",
                                }}
                              >
                                {t("my_pets")}
                              </Typography>
                              <select
                                style={{
                                  padding: "8px",
                                  backgroundColor: "#637579",
                                  borderRadius: "5px",
                                  marginBlock: "5px",
                                }}
                                id="pet"
                                className="form-select btn"
                                defaultValue={Object.keys(
                                  selectedCategory.values
                                ).toString()}
                                aria-label="Default select example"
                                onChange={(e) => {
                                  handlePetSelection(e.target.value);
                                  setServices(null);
                                }}
                              >
                                <option
                                  style={{ backgroundColor: "#0a313b" }}
                                  disabled
                                >
                                  {t("select")}
                                </option>
                                {Object.keys(jobs[0]).map((option, index) => (
                                  <option
                                    key={index}
                                    style={{ backgroundColor: "#0a313b" }}
                                    value={option}
                                  >
                                    {t(option)}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="d-flex col-md-10 col flex-column">
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "black",
                                  marginTop: "10px",
                                }}
                              >
                                {t("services")}
                              </Typography>
                              <MultiSelect
                                isObject={false}
                                style={{
                                  option: {
                                    color: "#fff",
                                    background: "black",
                                  },
                                }}
                                selectedValues={services}
                                onSelect={(value) => {
                                  setServices(value);
                                }}
                                onRemove={(value) => {
                                  setServices(value);
                                }}
                                placeholder=""
                                selectionLimit={3}
                                options={translatedOptions}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <button
                              style={{ width: "80%", justifyContent: "center" }}
                              className="btn"
                            >
                              {t("save")}
                            </button>
                          </div>
                        </form>
                      </Box>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCategoryForm;
