import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BreadCumb from "../../../components/Breadcrumb";

function VetDetail() {
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();

  useEffect(() => {
    const getVet = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}vet/${params.id}`);
        setValues(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getVet();
  }, [params]);

  return (
    <div>
      <BreadCumb path={t("vet_detail")} />
      <section className="shop-details-area pt-110 pb-50">
        {values && (
          <div className="container">
            <div className="shop-details-wrap">
              <div className="row">
                <div className="col-5">
                  <div className="shop-details-img-wrap">
                    <div className="shop-details-img">
                      <img
                        src={values?.image ? values?.image : "/img/unuser.png"}
                        width={"100%"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="shop-details-content">
                    <span>Veteriner</span>
                    <h2 className="title">{values.title}</h2>
                    <div className="shop-details-dimension">
                      <span>{t("introduction")}: </span>
                    </div>
                    <p>{values.desc}</p>
                    <div className="shop-details-dimension">
                      <span>{t("address")}: </span>
                    </div>
                    <p>{values.address}</p>
                    <div className="shop-details-dimension">
                      <span>{t("contact")}: </span>
                    </div>
                    <div style={{ width: "200px" }}>
                      <a
                        href={`tel:${values.contact}`}
                        rel="noopener noreferrer"
                      >
                        <p>{values.vetsPhone ? values.contact : "-"}</p>
                      </a>
                    </div>
                    {values?.web && (
                      <div className="shop-details-dimension">
                        <span>{t("Web")}: </span>
                      </div>
                    )}
                    <div style={{ width: "200px" }}>
                      <a
                        href={values?.web}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{values?.web}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      {values && (
        <section className="map-section">
          <div
            className="container"
            dangerouslySetInnerHTML={{ __html: values.iframe }}
          />
        </section>
      )}
    </div>
  );
}

export default VetDetail;
